import * as signalR from '@microsoft/signalr'
import store from '@/store'
import { createUrlHub } from '@/utils/market'
import { log } from '@/utils/logger'

let connection

export const marketStart = async () => {
  const orderType = store.getters['market/orderType']
  const url = createUrlHub(orderType)

  if (!url) {
    return
  }

  connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => localStorage.getItem('token')
    })
    .withAutomaticReconnect()
    .build()

  eventListener()

  try {
    await connection.start()
  } catch (e) {
    console.error(e.toString())
  }
}

export const marketStop = async () => {
  try {
    await connection.stop()
  } catch (e) {
    console.error(e.toString())
  }
}

const eventListener = () => {
  connection.on('OrderAdded', async (filterId, data) => {
    log('MARKETHUB - OrderAdded', filterId, data)
    await store.dispatch('market/addItem', { filterId, data })
  })
  connection.on('OrderRemoved', async (filterId, data) => {
    log('MARKETHUB - OrderRemoved', filterId, data)
    await store.dispatch('market/removeItem', { filterId, data })
  })
  connection.on('OrderModified', async (filterId, data) => {
    log('MARKETHUB - OrderModified', filterId, data)
    await store.dispatch('market/modifiedItem', { filterId, data })
  })
}
